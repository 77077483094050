
.payment-method-button {
    padding: 64px 56px;
    border-radius: 12px;
    border: 1px solid #E5E8F7;
    background: #FFF;
    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.16);

    color: #151F2F;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;

    display: flex;
    gap: 16px;
    align-items: center;
}

.payment-method-button:not(:disabled):hover {
    border: 1px solid #FF5948;
    background: linear-gradient(31deg, rgba(255, 89, 72, 0.05) 38.9%, rgba(255, 89, 72, 0.02) 92.26%);
    box-shadow: 10px 10px 20px 0px rgba(255, 89, 72, 0.10);
    color: #FF5948;
}

.payment-method-button-icon {
    width: 32px;
    height: 32px;
    background-color: #151F2F;
    border-radius: 50%;
    padding: 4px;
}

.payment-method-button:not(:disabled):hover .payment-method-button-icon {
    background-color: #FF5948;
}