
.level-one-button {
    min-width: 260px;
    min-height: 48px;

    background-color: #151F2F;
    border-radius: 24px;
    color: white;
    text-align: center;
    /* H5 🌈 */
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.level-one-button:not(:disabled) {
    filter: drop-shadow(4px 4px 8px rgba(20, 34, 114, 0.32));
}

.level-one-button:not(:disabled):hover {
    background-color: #10151F;
}

.level-one-button:disabled {
    background-color: #AFB9CE;
}
