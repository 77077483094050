.header {
    background: linear-gradient(to top right, #FF5948, #FAC698);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    flex-wrap: wrap;
    padding: 57px 130px;

    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #151F2F;
}

@media (max-width: 600px) {
    .header {
        padding: 42px 100px;
    }

    .header img {
        width: 120px;
        height: auto;
    }
}
  