
.previous-step-button {
    background-color: #F5F6FC;
    filter: drop-shadow(1.127px 2.254px 4.507px rgba(0, 21, 51, 0.10));
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #FF5948;
    cursor: pointer;
    padding: 4px;
}

.previous-step-button:not(:disabled):hover {
    background-color: #F2F2F4;
}
