@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');

.das-mas {
    color: #545454;
    font-family: Bitter, Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
}

/** **/

.das-mas-selector-button {
    font-size: 18px;
    color: #545454;
    background-color: white;
    border: 1px solid #eee;
    border-radius: 16px;
    padding: 32px;
    min-width: 200px;
    min-height: 200px;
    transition: all 0.4s ease;
}
    
.das-mas-selector-button:hover {
    box-shadow: rgba(109, 117, 141, 0.2) 0px 12px 48px;
    cursor: pointer;
}

.das-mas-selector-button img {
    width: 64px;
    margin-bottom: 8px;
}
