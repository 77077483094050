.app {
  width: 100%;
  font-size: calc(10px + 2vmin);
  color: #151F2F;
  padding: 10vh 0;
  padding: min(10vh, 124px) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imgContainer{
  height: 100vh;
  display: flex;
  align-items: center;
  width: 30%;
}

.landscapeImg {
  /* width: 480px; */
  max-height: 100vh;
  max-width: 100%;

}

.verticalImg {
  /* width: 480px; */
  max-height: 100vh;
  max-width: 80%;
  margin-top: -7px;
}

@media (max-width: 1100px) {
  .verticalImg {
    margin: auto;
    margin-top: 80vh;
    display: block;
  }
  .app {
    padding-left: 0px !important;
    position: absolute !important;
  }
}

.card {
  border-radius: 12px;
  border: 1px solid #E8F0F8;
  background: #FFF;
  padding: 32px;
}

label {
  width: 100%;
  /* Input/Label 🌈 */
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

label:has(input:disabled) {
  color: #AFB9CE;
}

input {
  width: 100%;
  display: block;
  padding: 7px 15px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid #AFB9CE;
}

input::placeholder {
  color: #68758E;
  font: inherit;
  opacity: 1;
}

input:disabled::placeholder {
  color: #AFB9CE;
}

.pageLogo {
  float: left;
  height: 28px;
  margin-top: 22px;
  margin-right: 10px;
  margin-left: 0px !important;
}
@media (max-width: 1000px) {
  .pageLogo {
    float: none;
    display: inline-block;
    margin-top: 0px;
    vertical-align: middle;
    margin-left: 50px;
  }
}

@media (max-width: 600px) {
  .imgContainer{
    display: none;
  }
}
.content {
  -webkit-animation: fadein .8s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein .8s; /* Firefox < 16 */
          animation: fadein .8s;
  transition: .2s;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 8px;
}

@keyframes fadein {
  from { 
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px); 
  }
}

@keyframes appear {
  0% { 
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loaderContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 100;
}
.loaderContainer .pageLogo {
  height: 40px;
  position: absolute;
  top: calc(50vh - 60px);
  left: calc(50vw - 35px);
}
.loadBar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 3px;
  background-color: orange;
  width: 99vw;
  z-index: 101;
  -webkit-animation: load 8s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: load 8s; /* Firefox < 16 */
          animation: load 8s;
  transition: .2s;
}

@keyframes load {
  0% { 
    width: 0%
  }
  20% {
    width: 50%;
  }
  50% {
    width: 80%;
  }
  100% {
    width: 99%;
  }
}
